import SmoothScroll from "smooth-scroll";

export default class SingleProductMobile {
  constructor() {
    this.subtaxes = document.querySelectorAll(".subtax-term");
    this.models = document.querySelectorAll(".products-list-widget a");
    this.productOverviews = document.querySelectorAll(".product-widget-item-thumb");
    this.scroll = new SmoothScroll(null, {
      header: "#masthead",
    });
    this.init();
  }

  init() {
    this.handleProductHash();
    if (this.subtaxes.length) this.handleSubtaxes();
    if (this.models.length) this.handleModels();
    if (this.productOverviews.length) this.handleOverviews();
  }

  handleOverviews() {
    this.productOverviews.forEach(image => {
      image.addEventListener("click", e => {
        e.preventDefault();
        const activeImage = document.querySelector(".product-widget-item-thumb.active");

        image.classList.contains("active") ? image.classList.remove("active") : image.classList.add("active");

        if (activeImage) activeImage.classList.remove("active");
      });
    });
  }

  handleProductHash() {
    let url = window.location;

    if (url.hash) {
      this.scroll.animateScroll(document.querySelector(url.hash));
      setTimeout(() => {
        this.scroll.animateScroll(document.querySelector(url.hash));
      }, 1000);
    }
  }

  handleSubtaxes() {
    this.subtaxes.forEach(subtax => {
      subtax.addEventListener("click", e => {
        e.preventDefault();

        if (subtax.classList.contains("active-term")) {
          this.scroll.animateScroll(document.getElementById("products-list"));
        } else {
          window.location = `${subtax.getAttribute("href")}#products-list`;
        }
      });
    });
  }

  handleModels() {
    this.models.forEach(model => {
      model.addEventListener("click", e => {
        e.preventDefault();

        if (model.closest(".product-list-item").classList.contains("current-menu-item")) {
          this.scroll.animateScroll(document.getElementById("product-content"));
        } else {
          window.location = `${model.getAttribute("href")}#product-content`;
        }
      });
    });
  }
}
