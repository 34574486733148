/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import { getScreenHeight } from "./helper.js";
import CoreAnimation from "./animations/CoreAnimation";
import $ from "jquery";

export const toggleMenu = () => {
  let button = document.getElementById("toggle"),
    container = document.getElementById("site-navigation"),
    html = document.querySelector("html");

  if (button && container) {
    button.addEventListener("click", () => {
      document.querySelectorAll("#site-navigation > div > ul").forEach(ul => CoreAnimation.slideToggle(ul));

      if (button.classList.contains("open")) {
        button.classList.remove("open");
        html.style.overflowY = "auto";
        return;
      }

      button.classList.add("open");

      if (getScreenHeight() <= 1260) {
        html.style.overflowY = "hidden";
      } else {
        html.style.overflowY = "auto";
      }
    });
  }
};

export const closeMenu = () => {
  let links = document.querySelectorAll(".main-navigation a"),
    nav = document.getElementById("site-navigation"),
    toggle = document.getElementById("toggle");

  links.forEach(e => {
    e.addEventListener("click", () => {
      nav.classList.remove("open");
      toggle.classList.remove("open");
    });
  });
};

export const addSticky = () => {
  let toggle = document.getElementById("toggle"),
    header = document.getElementById("masthead");

  toggle.addEventListener("click", () => {
    header.classList.add("sticky");
  });
};

$(".main-navigation .menu-item-has-children > a").on("click", function(e) {
  var device_width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  if (device_width < 1260) {
    e.preventDefault();
    var li = $(this).closest("li");
    var ul = li.children("ul");
    li.toggleClass("toggled");
    if (ul.attr("aria-expanded") == "true") {
      ul.attr("aria-expanded", "false");
    } else {
      ul.attr("aria-expanded", "true");
    }
  }
});
