import { isMobile, tabsModule } from "./helper";
import SiteHeader from "./siteHeader";
import SingleProductMobile from "./singleProductMobile";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import $ from "jquery";
import TinyDatePicker from "tiny-date-picker";
import "tiny-date-picker/tiny-date-picker.min.css";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  const tabsContainers = document.querySelectorAll(".tabs-container");

  if (tabsContainers.length) {
    tabsContainers.forEach(container => tabsModule(container));
  }

  if (isMobile()) {
    closeMenu();
    addSticky();
    new SingleProductMobile();
  }

  $(".generatepdf").on("click", function(e) {
    e.preventDefault();
    var but = $(this);
    var loader = but.find("img");
    var my_content = $("body").find(".product-main-content-inner");

    loader.show();
    my_content = my_content.html();

    var my_title = $("body")
      .find(".page-title")
      .text();

    var my_schemat = $("body")
      .find(".product-schematy-container a")
      .first()
      .attr("href");

    var data = {
      action: "generatepdf",
      content: my_content,
      title: my_title,
      schemat: my_schemat,
    };

    $.post(ajax_options.admin_ajax_url, data, function(response) {
      loader.hide();

      response = JSON.parse(response);
      window.open(response.pdfurl);
    });
  });

  let datePickers = document.querySelectorAll("input.datepicker");

  if (datePickers.length) {
    const date = new Date();
    const monthNames = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"];

    const pickerSettings = {
      lang: {
        days: ["Nd", "Pn", "Wt", "Śr", "Czw", "Pt", "Sob"],
        months: monthNames,
        today: "Dzisiaj",
        clear: "Wyczyść",
        close: "Zamknij",
      },
      min: date,
      dayOffset: 0,
      // format: function(date) {
      //   const day = date.getDate();
      //   const monthIndex = date.getMonth();
      //   const year = date.getFullYear();
      //
      //   return `${monthIndex + 1}/${day}/${year}`;
      // },
    };

    // datePickers.forEach(picker => );
    const fromDatePicker = TinyDatePicker(document.querySelector(".preferred-date-from"), pickerSettings);
    const toDatePicker = TinyDatePicker(document.querySelector(".preferred-date-to"), pickerSettings);
    fromDatePicker.on("statechange", (_, picker) =>
      toDatePicker.setState({
        min: fromDatePicker.state.selectedDate,
      })
    );
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
