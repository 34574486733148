//https://www.npmjs.com/package/smooth-scroll

import SmoothScroll from "smooth-scroll";
import $ from "jquery";

const scroll = new SmoothScroll('a[href*="#"]', {
  ignore: "[data-vc-container], .vc_carousel-control, .tab-heading",
  header: "#masthead",
});

$(".sprawdz-cennik").on("click", function() {
  var target_tab = $(this).data("target-tab");
  var tabs = $("#taxonomy-tabs .tab-heading");

  tabs.each(function() {
    if ($(this).data("tab") == target_tab) {
      $(this).trigger("click");
      scroll.animateScroll($("#taxonomy-tabs")[0]);
    }
  });
});
