import SeasonsService from "./services/seasons-service";

function IsEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

(function($) {
  $("document").ready(function() {
    var submitted = false;
    var output_cena = $(".kalk-cena");
    var cena = 0;
    var form = $("#kalk-form");
    var inputs = $(".sezon-days-input");
    var checkboxes = $(".kalk-upust-input");
    var usluga = $("#kalk-usluga");
    var calcData = form.data("calc");

    if (form.hasClass("calc-form-container")) {
      new SeasonsService(calcData);
    }

    $("#kalk-upust-ilosc").attr("disabled", true);
    usluga.attr("disabled", true);
    var changed = false;
    function changeCosts() {
      var multiplier = 1;
      var days = 0;
      var single_cena = 0;
      var upust = 0;
      cena = 0;
      var firstInput = null;
      $.each(inputs, function() {
        if ($(this).val() > 0) {
          if (firstInput && !changed) {
            firstInput.val(firstInput.val() - 1);
            changed = true;
          }
          firstInput = $(this);
        }
      });
      $.each(inputs, function() {
        single_cena = parseInt($(this).data("cena"));
        multiplier = parseInt($(this).val()) || 0;
        // console.log($(this));
        $(this).val(multiplier);

        cena += single_cena * multiplier;
        days += multiplier;
      });
      if (days >= 21) {
        $("#kalk-upust-ilosc").attr("checked", true);
      } else {
        $("#kalk-upust-ilosc").attr("checked", false);
      }

      if (days > 0 && days < 14) {
        usluga.attr("checked", true);
      } else {
        usluga.attr("checked", false);
      }

      if (days >= 14) {
        $(".usluga-serwisowa-on").slideDown();
        $(".usluga-serwisowa-off").slideUp();
      } else {
        $(".usluga-serwisowa-off").slideDown();
        $(".usluga-serwisowa-on").slideUp();
      }

      $.each(checkboxes, function() {
        if ($(this).attr("checked") == "checked") {
          upust += cena * $(this).data("upust") * 0.01;
        }
      });

      cena -= Math.ceil(upust);

      if (cena >= 0 && usluga.attr("checked") == "checked") {
        cena += parseInt(usluga.val());
      }

      output_cena.text(cena + " PLN");
    }

    checkboxes.on("change", function() {
      if ($(this).attr("id") == "kalk-upust-trzy") {
        $("#kalk-upust-dwa").attr("checked", false);
      }
      if ($(this).attr("id") == "kalk-upust-dwa") {
        $("#kalk-upust-trzy").attr("checked", false);
      }

      changeCosts();
    });

    inputs.on("change keyup", function() {
      changeCosts();
    });

    usluga.on("change", function() {
      changeCosts();
    });

    $(".contact-input-name").on("change", function() {
      if ($(this).val() == "") {
        $(this).addClass("invalid");
      } else {
        $(this).removeClass("invalid");
      }
    });

    $(".contact-input-tel").on("change", function() {
      if ($(this).val() == "") {
        $(this).addClass("invalid");
      } else {
        $(this).removeClass("invalid");
      }
    });

    $(".contact-input-email").on("change", function() {
      if (!IsEmail($(this).val())) {
        $(this).addClass("invalid");
      } else {
        $(this).removeClass("invalid");
      }
    });

    form.submit(function(event) {
      event.preventDefault();

      var form = $(this);
      $(".kalk-result-error").slideUp();
      $(".kalk-result-ok").slideUp();

      if (!submitted) {
        submitted = true;

        var hasError = false;

        if ($(".contact-input-name").val() == "") {
          hasError = true;
          $(".contact-input-name").addClass("invalid");
        }

        if ($(".contact-input-tel").val() == "") {
          hasError = true;
          $(".contact-input-tel").addClass("invalid");
        }

        if (!IsEmail($(".contact-input-email").val())) {
          hasError = true;
          $(".contact-input-email").addClass("invalid");
        }

        if (!hasError) {
          $.post(form.attr("action"), form.serialize(), function(response) {
            var status = $(response)
              .find(".form-status")
              .text();
            if (status == "OK") {
              form
                .find('input[type="number"]')
                .val("")
                .attr("disabled", true);
              form
                .find('input[type="text"]')
                .val("")
                .attr("disabled", true);
              form
                .find('input[type="checkbox"]')
                .attr("checked", false)
                .attr("disabled", true);
              form
                .find("textarea")
                .val("")
                .attr("disabled", true);
              form.find('input[type="submit"]').slideUp();
              output_cena.text("0 PLN");
              $(".kalk-result-ok").slideDown();
              $(".kalk-result-error").slideUp();
            } else {
              submitted = false;
              $(".kalk-result-error").slideDown();
              $(".kalk-result-ok").slideUp();
            }
          });
        } else {
          setTimeout(function() {
            submitted = false;
          }, 300);
        }
      }
    });
  });
})(jQuery);
