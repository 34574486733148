// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  logo: $(".logo-carousel"),
  home: $(".home-slider .inner"),
  gallery: $(".product-gallery"),
};

sliders.home.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  cssEase: "ease",
  slidesToShow: 1,
});

sliders.logo.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  cssEase: "ease",
  pauseOnHover: false,
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});

if (sliders.gallery) {
  let $pagination = $(".product-gallery-container .gallery-pagination");
  sliders.gallery.on("init reInit afterChange", function(event, slick, currentSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;

    $pagination.html(`<span class="current">${i}</span><span class="separator"></span><span class="total">${slick.slideCount}</span>`);
  });

  sliders.gallery.slick({
    dots: false,
    arrows: true,
    autoplay: true,
    cssEase: "ease",
    pauseOnHover: false,
    slidesToShow: 1,
  });
}
