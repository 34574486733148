export default class FloatLabels {
  constructor(
    defaultSettings = {
      container: ".form-field input, .form-field textarea, .form-field select",
    }
  ) {
    this.defaultSettings = defaultSettings;
    this.inputs = document.querySelectorAll(this.defaultSettings.container);
    this.init();
  }

  init() {
    this.inputs.forEach(el => {
      el.addEventListener("focus", e => this.onFocus(e));
      el.addEventListener("blur", e => this.onBlur(e));

      if (el.tagName == "SELECT") {
        el.addEventListener("change", e => this.onChange(e));
      } else {
        el.addEventListener("keypress", e => this.onKeyPress(e));
      }

      if (el.value !== "") {
        el.closest(".form-field").classList.add("active");
      }
    });
  }

  addActive(e) {
    e.target.closest(".form-field").classList.add("active");
  }

  removeActive(e) {
    e.target.closest(".form-field").classList.remove("active");
  }

  onFocus(e) {
    this.addActive(e);
  }

  onKeyPress(e) {
    if (e.target.value !== "") {
      this.addActive(e);
    }
  }

  onChange(e) {
    if (e.target.value !== "") {
      this.addActive(e);
    } else {
      this.removeActive(e);
    }
  }

  onBlur(e) {
    if (e.target.value === "") {
      this.removeActive(e);
    }
  }
}
