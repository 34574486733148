export default class SeasonsService {
  constructor(calcData) {
    this.dateFrom = document.querySelector('input[name="kalk-date-from"]');
    this.dateTo = document.querySelector('input[name="kalk-date-to"]');
    this.calcData = calcData;
    this.seasonDaysCount = {
      a: 0,
      b: 0,
      c: 0,
      d: 0,
    };
    this.yearFrom = new Date().getFullYear();
    this.yearTo = new Date().getFullYear();

    this.init();
  }

  init() {
    this.dateFrom.addEventListener("change", () => this.handleDateInput());
    this.dateTo.addEventListener("change", () => this.handleDateInput());
  }

  handleDateInput() {
    if (this.dateFrom.value && this.dateTo.value) {
      let [monthFrom, dayFrom, yearFrom] = this.dateFrom.value.split("/");
      let [monthTo, dayTo, yearTo] = this.dateTo.value.split("/");
      let dateObjFrom = new Date(yearFrom, parseInt(monthFrom) - 1, dayFrom);
      let dateObjTo = new Date(yearTo, parseInt(monthTo) - 1, dayTo);

      if ([12, 1, 2].includes(parseInt(monthFrom)) || [12, 1, 2].includes(parseInt(monthTo))) {
        document.querySelector(".kalk-cena").innerHTML = "W miesiącach grudzień, styczeń i luty wypożyczalnia nieczynna";
        return;
      }

      this.yearFrom = yearFrom;
      this.yearTo = yearTo;

      if (dateObjFrom.getTime() < dateObjTo.getTime()) {
        this.resetCounter();
        this.countReservationDays(dateObjFrom, dateObjTo);
      }
    }
  }

  resetCounter() {
    for (let key in this.seasonDaysCount) {
      if (this.seasonDaysCount.hasOwnProperty(key)) {
        this.seasonDaysCount[key] = 0;
      }
    }
  }

  countReservationDays(dateFrom, dateTo) {
    const reservationDays = this.daysBetween(dateFrom, dateTo);

    for (let dayIndex = 0; dayIndex < reservationDays; dayIndex++) {
      const dateClone = new Date(dateFrom.getTime());
      const currentDay = dateClone.setDate(dateClone.getDate() + dayIndex);
      this.checkDaySeason(currentDay);
    }

    this.fillSeasonFields();
  }

  checkDaySeason(day) {
    for (let key in this.calcData.seasons) {
      if (this.calcData.seasons.hasOwnProperty(key)) {
        for (let index = 0; index < this.calcData.seasons[key].length; index++) {
          const element = this.calcData.seasons[key][index];
          let [tresholdFrom, tresholdTo] = element.split("-");
          let [fromDay, fromMonth] = tresholdFrom.trim().split(".");
          let [toDay, toMonth] = tresholdTo.trim().split(".");
          let dateObjFrom = new Date(this.yearFrom, parseInt(fromMonth) - 1, fromDay);
          let dateObjTo = new Date(this.yearTo, parseInt(toMonth) - 1, toDay);

          if (day >= dateObjFrom.getTime() && day <= dateObjTo.getTime()) {
            this.seasonDaysCount[key]++;
          }
        }
      }
    }
  }

  fillSeasonFields() {
    for (let key in this.seasonDaysCount) {
      if (this.seasonDaysCount.hasOwnProperty(key)) {
        const field = document.querySelector(`.sezon-days-input.sezon-${key}`);
        const event = new Event("change");

        if (field) {
          field.value = this.seasonDaysCount[key];
          field.dispatchEvent(event);
        }
      }
    }
  }

  daysBetween(date1, date2) {
    const oneDay = 1000 * 60 * 60 * 24;
    const date1InMs = date1.getTime();
    const date2InMs = date2.getTime();
    const differenceInMs = date2InMs - date1InMs;

    return Math.round(differenceInMs / oneDay);
  }
}
